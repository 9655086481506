<template>
  <v-row>
    <v-col cols="12">
      <ClassLists :role="role" />
    </v-col>
  </v-row>
</template>

<script>
import ClassLists from "@/packages/admin/components/classRoom/ClassTable";
import { mapGetters } from "vuex";
export default {
  name: "classRoom",
  components: { ClassLists },
  computed: {
    ...mapGetters({
      role: "authentication/role",
    }),
  },
};
</script>

<style scoped></style>
