<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="d-flex justify-start flex-fill flex-wrap px-4">
          <span class="d-flex align-center flex-row mr-9">
            <v-sheet
              width="15px"
              height="15px"
              :color="event_colors.canceled"
              class="rounded-pill mr-2"
            ></v-sheet>
            {{ $_t("attribute.studentHaveClass") }}
          </span>
          <span class="d-flex align-center flex-row mr-9">
            <v-sheet
              width="15px"
              height="15px"
              :color="event_colors.full"
              class="rounded-pill mr-2"
            ></v-sheet>
            {{ $_t("attribute.teacherHaveClass") }}
          </span>
        </div>
        <v-calendar
          ref="studentCalendar"
          v-model="focus"
          :locale="$i18n.locale"
          color="primary"
          :events="studentsEvents"
          :event-color="getEventColor"
          type="week"
        >
          <template v-slot:event="{ event, eventSummary }">
            <div style="height: 100%">
              <div>
                {{ `${$_t("attribute.room")}: ${event.classroomNumber}` }}
              </div>
              <div v-if="event && event.showTeacher">
                {{ `${$_t("attribute.teacher")}: ${event.teacherName}` }}
              </div>
              <div v-if="event">
                {{
                  `${$_t("attribute.baseType")}: ${$_t(
                    `attribute.${event.baseType}`
                  )}`
                }}
              </div>
              <div class="v-event-draggable">
                <component :is="{ render: eventSummary }"></component>
              </div>
            </div>
          </template>
        </v-calendar>
        <v-divider></v-divider>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $_t("attribute.editClass") }}</v-toolbar-title>
          <AddSessionModal @addSessions="addSessions" :events="events">
            <template #activator>
              <v-btn
                v-if="!loading"
                :disabled="loading"
                class="ml-2"
                block
                outlined
                color="#fff"
                >{{ $_t("attribute.sessions") }}</v-btn
              >
            </template>
          </AddSessionModal>
          <v-spacer></v-spacer>
          <v-chip
            color="blue"
            class="mx-2"
            v-for="t in teachingHours"
            :key="t.month"
          >
            {{ `${t.month}: ${t.teacherTotalClassroomsHours} H` }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn color="#fff" class="px-0 mr-3" @click="prev">
            <v-icon large color="primary"> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn outlined class="mr-4" color="#fff" @click="setToday">
            {{ $_t("attribute.today") }}
          </v-btn>
          <v-btn color="#fff" class="px-0 mr-4" @click="next">
            <v-icon large color="primary"> mdi-chevron-right </v-icon>
          </v-btn>
          <!--            <v-spacer />-->
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.getTitle }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark :disabled="loading" text @click="saveChanges">
              {{ $_t("attribute.save") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container fluid>
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            @change="updateRange"
            :events="mixEvents"
            :event-color="getEventColor"
            type="week"
            :locale="$i18n.locale"
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag"
          >
            <template v-slot:event="{ event, timed, eventSummary }">
              <div style="height: 90%">
                <div class="v-event-draggable">
                  <div v-if="event.classroomNumber">
                    {{ `${event.classroomNumber}` }}
                  </div>
                </div>
                <div
                  class="v-event-draggable"
                  v-if="event.lesson || event.baseType"
                >
                  {{
                    `${
                      event?.lesson.map((l) => `${$_t(`attribute.${l}`)}`) ||
                      event?.baseType.map((l) => `${$_t(`attribute.${l}`)}`)
                    }`
                  }}
                  <br />
                  <div v-if="event.teacherName">
                    {{ `${$_t("attribute.teacher")}: ${event.teacherName}` }}
                  </div>
                </div>
                <div class="v-event-draggable">
                  <component :is="{ render: eventSummary }"></component>
                </div>
              </div>
              <div
                v-if="timed && typeof event.availableTimeId === 'number'"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              ></div>
            </template>
          </v-calendar>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";
import message from "@/tools/Message";
import AddSessionModal from "@/packages/admin/components/classRoom/AddSessionModal";

export default {
  name: "EditClassModal",
  components: { AddSessionModal },
  computed: {
    ...mapGetters({
      singleClass: "class/getClass",
      teacherEvents: "class/getTeacherEvents",
      TE: "class/teacherEvents",
      studentsEvents: "class/getStudentEvents",
      // loading: "loading/is_loading",
    }),
    dialog: {
      get() {
        return this.$store.getters["dialog/dialog"];
      },
      set(value) {
        return this.$store.commit("dialog/setDialog", value);
      },
    },
    mixEvents() {
      return [...this.teacherEvents, ...this.events];
    },
  },
  data() {
    return {
      loading: false,
      classroomNumber: "",
      events: [],
      focus: "",
      startWeek: null,
      endWeek: null,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      event_colors,
      classModal: false,
      teachingHours: [],
      start: null,
      end: null,
    };
  },
  watch: {
    async dialog(val) {
      if (val) {
        this.loading = true;
        this.teachingHours = await this.$actions.getTeacherMonthlyHours({
          teacherPublicKey: this.singleClass.teacherPublicKey,
        });
        await this.$actions.getNotAvailableRoomsAndTimes({
          teacherPublicKey: this.singleClass.teacherPublicKey,
        });
        await this.$actions.getNotAvailableTimesOfStudent({
          publicKeys: this.singleClass.students.map((s) => s.studentPublicKey),
        });
        await this.$actions.getClassNumbers();
        await this.$actions.getTeacherTimetable({
          all: true,
          role: "Admin",
          dispatch: "class/setTeacherEvents",
          publicKey: [this.singleClass.teacherPublicKey],
          lessonName: this.singleClass.lessonName,
        });

        this.events = this.singleClass.times
          .filter((time) => time.state !== "Cancel")
          .map((time) => {
            const status = this.singleClass.students.find((s) => {
              return s.presenceStatus.find(
                (s) =>
                  s.availableTimeId === time.availableTimeId &&
                  s.start === time.start &&
                  s.end === time.end
              );
            });
            const info = this.singleClass.teachingDayInformation?.find(
              (i) =>
                i.availableTimeId === time.availableTimeId &&
                i.start === time.start &&
                i.end === time.end
            );

            return {
              ...time,
              end: time.end * 1000,
              start: time.start * 1000,
              disabled: !!(
                time.changesConfirmedByTeacher ||
                time.changesConfirmedBySchedulingAdmin
              ),
              id: time.availableTimeId,
              availableTimeId:
                info || status ? time.availableTimeId : this.generateId(),
              color: info || status ? event_colors.canceled : event_colors.new,
              timed: true,
            };
          });
        this.loading = false;

        // console.log(this.events);
        // console.log(
        //   this.singleClass.times.filter((time) => time.state === "Cancel")
        // );
      } else {
        await this.$store.commit("class/updateClass", null);
        this.loading = false;
      }
    },
  },
  methods: {
    addSessions(times) {
      this.events = times;
    },
    async saveChanges() {
      if (!this.events.length) {
        message.error("Please select some events");
        return false;
      }
      if (this.events.find((e) => !e.classroomNumber)) {
        message.error("Please select some room");
        return false;
      }
      let min = Number.POSITIVE_INFINITY;
      this.events.forEach((e) => {
        let tmp = e.start;
        if (tmp < min) min = tmp;
      });
      let events = [
        ...this.singleClass.times.filter((time) => time.state === "Cancel"),
      ];
      this.TE.filter((t) => t.end > min).forEach((tt) => {
        for (const event of this.events) {
          if (event.start >= tt.start && event.end <= tt.end) {
            events.push({
              classroomNumber: event.classroomNumber,
              end: event.end / 1000,
              start: event.start / 1000,
              availableTimeId: tt.availableTimeId,
              message: null,
              state: event.state || "Pending",
              financialConfirmation: event.financialConfirmation,
              firstReservedDate: event.firstReservedDate,
              changesConfirmedByTeacher: event.changesConfirmedByTeacher
                ? event.changesConfirmedByTeacher
                : false,
              changesConfirmedBySchedulingAdmin:
                event.changesConfirmedBySchedulingAdmin
                  ? event.changesConfirmedBySchedulingAdmin
                  : false,
            });
          }
        }
      });
      await this.$actions.editTimesAndLessons({
        classroomId: this.singleClass.classroomId,
        times: events,
      });
      this.dialog = false;
      this.$emit("update");
    },
    async updateRange({ start, end }) {
      const viewStart = this.$refs.calendar.getStartOfWeek(start);
      const startDate = new Date(viewStart.date).getTime();

      let viewEnd = this.$refs.calendar.getEndOfWeek(end);
      const endDate = new Date(`${viewEnd.date}T23:59:59`).getTime();
      this.start = startDate / 1000;
      this.end = endDate / 1000;

      await this.getClassroomsTimes();
    },
    async getClassroomsTimes() {
      await this.$actions.getClassroomsTimes({
        teacherPublicKey: this.singleClass.teacherPublicKey,
        baseType: this.singleClass.lessonName,
        start: this.start,
        end: this.end,
      });
    },
    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.calendar.prev();
      this.$refs.studentCalendar.prev();
    },
    next() {
      this.$refs.calendar.next();
      this.$refs.studentCalendar.next();
    },
    setToday() {
      this.focus = "";
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          color: this.event_colors.new,
          start: this.createStart,
          end: this.createStart,
          availableTimeId: Math.floor(Math.random() * 1000 + Date.now()),
          timed: true,
        };

        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        if (typeof this.dragEvent.availableTimeId === "string") return;
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        if (typeof this.createEvent.availableTimeId === "string") return;
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
  },
};
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
