import { _t } from "@/tools/Utils";
import { levels_filter } from "@/packages/admin/schema/LEVELS";

export const CLASS_TABLE_SCHEMA = {
  headers: [
    {
      text: _t("attribute.classroomName"),
      align: "start",
      sortable: false,
      value: "classroomName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.teacher"),
      sortable: false,
      value: "teacherName",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.times"),
      sortable: false,
      value: "times",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.baseType"),
      sortable: false,
      value: "baseType",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.classroomType"),
      sortable: false,
      value: "type",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.financialState"),
      sortable: false,
      value: "financialState",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.state"),
      sortable: false,
      value: "state",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: "",
      sortable: false,
      value: "edit",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: "",
      sortable: false,
      value: "actions",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: "",
      sortable: false,
      value: "delete",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
  ],
  teacher_headers: [
    {
      text: _t("attribute.classroomName"),
      align: "start",
      sortable: false,
      value: "classroomName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.teacher"),
      sortable: false,
      value: "teacherName",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.times"),
      sortable: false,
      value: "times",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.baseType"),
      sortable: false,
      value: "baseType",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.classroomType"),
      sortable: false,
      value: "type",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.state"),
      sortable: false,
      value: "state",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.details"),
      sortable: false,
      value: "detail",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: "",
      sortable: false,
      value: "actions",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
  ],
  filters: [...levels_filter],
};
