<template>
  <v-dialog width="950px" v-model="modal" transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-bind="attrs" v-on="on">
        <slot name="activator" />
      </div>
    </template>
    <template #default>
      <v-card class="px-4">
        <v-toolbar class="elevation-0" color="transparent">
          <span class="text-h5 font-weight-bold">{{
            $_t("attribute.addSessions")
          }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-4">
          <div class="text--disabled">
            {{ $_t("attribute.fill_and_create_your_period") }}
            <v-spacer></v-spacer>
            <v-btn class="mb-4" color="primary" @click="addSession"
              >{{ $_t("attribute.add") }}<v-icon>mdi-plus</v-icon></v-btn
            >
          </div>
          <div v-if="forms.length" class="mt-4">
            <v-row
              v-for="form in forms"
              class="justify-space-between"
              :key="form.availableTimeId"
            >
              <v-col cols="2" class="d-flex justify-center align-center">
                {{ $_date(form.date, "day") }}
              </v-col>
              <v-col cols="2">
                <v-menu
                  ref="menu"
                  :close-on-content-click="true"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date"
                      :label="$_t(`attribute.date`)"
                      dense
                      outlined
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :disabled="form.disabled"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date"
                    no-title
                    color="primary"
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  dense
                  outlined
                  hide-details
                  color="primary"
                  :label="$_t(`attribute.start`)"
                  type="time"
                  v-model="form.start"
                  :disabled="form.disabled"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  dense
                  outlined
                  hide-details
                  color="primary"
                  :label="$_t(`attribute.end`)"
                  type="time"
                  v-model="form.end"
                  :disabled="form.disabled"
                />
              </v-col>
              <v-col cols="2">
                <v-select
                  outlined
                  dense
                  hide-details
                  :label="$_t('attribute.classroomNumber')"
                  v-model="form.classroomNumber"
                  :items="classNumbers"
                  :disabled="form.disabled"
                ></v-select>
              </v-col>
              <v-col cols="1">
                <v-btn
                  color="primary"
                  outlined
                  small
                  fab
                  :disabled="form.disabled"
                  @click="deleteSession(form.availableTimeId)"
                  ><v-icon>mdi-minus</v-icon></v-btn
                >
              </v-col>
              <v-col cols="1">
                <v-btn
                  color="primary"
                  outlined
                  small
                  fab
                  :disabled="form.disabled"
                  @click="copySession(form)"
                  ><v-icon>mdi-content-copy</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="balances.length">
              <v-col cols="12">
                <v-list-item v-for="(balance, index) in balances" :key="index">
                  <v-list-item-content>
                    <v-list-item-content>
                      {{
                        `${$_t("attribute.fullName")} : ${
                          balance.studentFullName
                        }`
                      }}
                    </v-list-item-content>
                    <v-list-item-content>
                      {{
                        `${$_t("attribute.balanceInHour")} : ${
                          balance.balanceInHour
                        } H`
                      }}
                    </v-list-item-content>
                    <v-list-item-content>
                      {{
                        `${$_t("attribute.haveClassFee")} : ${
                          balance.haveClassFee
                        }`
                      }}
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-divider />
                    </v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <MainSoftForm>
          <template v-slot:body> </template>
          <template v-slot:action>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  {{ $_t("attribute.cancel") }}
                </v-btn>

                <v-btn
                  color="primary"
                  :disabled="!forms.length || isLoading || disable"
                  class="ml-4"
                  @click="submit"
                >
                  {{ $_t("attribute.add") }}
                </v-btn>
              </div>
            </v-col>
          </template>
        </MainSoftForm>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import { getMainTime } from "@/tools/Utils";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";
import { mapGetters } from "vuex";
import message from "@/tools/Message";

export default {
  name: "AddSessionModal",
  components: { MainSoftForm },
  data() {
    return {
      getMainTime,
      modal: false,
      forms: [
        {
          date: null,
          start: null,
          end: null,
          classroomNumber: null,
          availableTimeId: this.generateId(),
        },
      ],
      event_colors,
      balances: [],
      disable: false,
    };
  },
  props: {
    events: {
      type: [Array, null],
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      classNumbers: "class/classroomNumbers",
      singleClass: "class/getClass",
      isLoading: "loading/is_loading",
    }),
  },
  watch: {
    async modal(value) {
      if (value) {
        console.log(this.singleClass, "class");
        if (this.singleClass) {
          this.balances = await this.$actions.getBalanceOfStudentsOfClassroom(
            this.singleClass.classroomId
          );
        }
        if (this.events) {
          console.log(this.events);
          this.disable = true;
          this.forms = [];
          [...this.events]
            .sort((a, b) => b.start - a.start)
            .forEach((event) => {
              this.forms.push({
                ...event,
                disabled: !!event.disabled,
                date: this.$_date(event.start, "ISO"),
                start: this.getMainTime(event.start),
                end: this.getMainTime(event.end),
              });
            });
          this.disable = false;
        }
      } else {
        this.clear();
      }
    },
  },
  methods: {
    close() {
      this.modal = false;
      this.clear();
    },
    submit() {
      if (!this.forms.length) return message.error(this.$_t("EMPTY_TIME"));
      if (
        this.forms.find(
          (t) => !t.start || !t.end || !t.date || !t.classroomNumber
        )
      )
        return message.error(this.$_t("FILL_CORRECT"));

      const times = this.forms.map((t) => {
        const start = new Date(`${t.date} ${t.start}`);
        const end = new Date(`${t.date} ${t.end}`);
        return {
          financialConfirmation: t.financialConfirmation || true,
          firstReservedDate: t.firstReservedDate || new Date().toISOString(),
          color: this.event_colors.new,
          start: start.getTime(),
          end: end.getTime(),
          availableTimeId: this.generateId(),
          classroomNumber: t.classroomNumber,
          timed: true,
        };
      });
      this.$emit("addSessions", times);
      this.close();
    },
    addSession() {
      this.forms.push({
        date: null,
        start: null,
        end: null,
        availableTimeId: this.generateId(),
        financialConfirmation: true,
        firstReservedDate: new Date().toISOString(),
      });
    },
    deleteSession(id) {
      this.forms = this.forms.filter((c) => c.availableTimeId !== id);
    },
    copySession(form) {
      this.forms.push({ ...form, availableTimeId: this.generateId() });
    },
    clear() {
      this.forms = [
        {
          date: null,
          start: null,
          end: null,
          classroomNumber: null,
          availableTimeId: this.generateId(),
          financialConfirmation: true,
          firstReservedDate: new Date().toISOString(),
        },
      ];
    },
    open() {
      this.modal = true;
    },
  },
};
</script>
