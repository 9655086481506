<template>
  <v-dialog v-model="isShowVerifyModal" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-on="on" v-bind="attrs">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card>
        <v-toolbar class="elevation-0" color="transparent">
          <v-btn fab color="gray lighten-5" small class="elevation-0 mr-3">
            <v-icon color="gray">mdi-check-outline</v-icon>
          </v-btn>
          <span class="text-h5 font-weight-bold">
            {{ $_t("attribute.continue") }}
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancel">
            <v-icon color="gray">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row v-if="showMessage">
            <v-col cols="12">
              <v-textarea
                outlined
                :label="$_t('attribute.description')"
                v-model="message"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-title class="text-h5 text-break">
          {{ $_t("attribute.continue_sure") }}
        </v-card-title>
        <v-card-text class="gray--text">
          {{ $_t("attribute.action_is_permanent") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel"> {{ $_t("attribute.cancel") }} </v-btn>
          <v-btn color="green darken-1" dark @click="confirm">
            {{ $_t("attribute.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    showMessage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowVerifyModal: false,
      message: "",
    };
  },
  methods: {
    open() {
      this.isShowVerifyModal = true;
    },
    cancel() {
      this.message = "";
      this.isShowVerifyModal = false;
    },
    confirm() {
      this.$emit("confirm", { message: this.message });
      this.cancel();
    },
  },
};
</script>
