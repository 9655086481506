<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="12" class="pt-5">
        <TopTitle :title="$_t('admin.components.class_table.title')" />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="4" v-if="!['Teacher'].includes(role)">
        <v-select
          hide-details
          flat
          outlined
          class="elevation-0"
          background-color="inputColor"
          :label="$_t('attribute.filter_by_teacher')"
          dense
          :items="teachers"
          v-model="teacherPublicKey"
          @change="initClasses({})"
          multiple
          clearable
          :item-text="(item) => `${item.fullName} | ${item.nickName}`"
          item-value="publicKey"
        >
          <template v-slot:prepend-item>
            <div class="px-3 py-2">
              <v-text-field
                outlined
                :label="$_t('attribute.searchTeacher')"
                dense
                hide-details
                @input="fetchTeachers($event)"
              />
            </div>
          </template>
          <template v-slot:selection="{ index, item }">
            <v-chip color="primary" v-if="index < 4" class="my-2">
              <span>{{ truncateString(item.fullName, 10) }}</span>
            </v-chip>
            <span v-if="index === 4" class="grey--text text-caption">
              (+{{ teacherPublicKey.length - 4 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="4" v-if="!['Teacher'].includes(role)">
        <v-select
          hide-details
          flat
          outlined
          class="elevation-0"
          dense
          background-color="inputColor"
          :label="$_t('attribute.filter_by_students')"
          :items="students"
          v-model="studentPublicKey"
          @change="initClasses({})"
          clearable
          :item-text="(item) => `${item.fullName} | ${item.nickName}`"
          item-value="publicKey"
        >
          <template v-slot:prepend-item>
            <div class="px-3 py-2">
              <v-text-field
                outlined
                :label="$_t('attribute.search')"
                dense
                hide-details
                @input="fetchStudents($event)"
              />
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="4" v-if="!['Teacher'].includes(role)">
        <v-select
          hide-details
          flat
          outlined
          class="elevation-0"
          background-color="inputColor"
          :label="$_t('attribute.filter_by_consultants')"
          dense
          :items="consultants"
          v-model="consultantPublicKey"
          @change="initClasses({})"
          clearable
          :item-text="(item) => `${item.fullName} | ${item.nickName}`"
          item-value="publicKey"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-select
          flat
          outlined
          class="elevation-0"
          dense
          background-color="inputColor"
          :label="$_t('attribute.level')"
          :items="filters"
          @change="initClasses({})"
          v-model="lessonType"
          item-text="text"
          item-value="value"
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-select
          flat
          outlined
          :label="$_t('attribute.baseType')"
          class="elevation-0"
          dense
          background-color="inputColor"
          :items="baseTypeFilter"
          @change="initClasses({})"
          v-model="baseType"
          item-text="name"
          item-value="value"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="
            pageInfo.page = 1;
            initClasses({ search: $event });
          "
          :headers="headers"
          :items="classes"
          :page-info="pageInfo"
          @detail="showDetail"
          @delete="deleteItem"
          :loading="is_loading"
          :hideDelete="true"
        >
          <template v-slot:top-right>
            <v-btn
              @click="fixReservations"
              v-if="
                [
                  'Admin',
                  'FinancialAdmin',
                  'SchedulingAdmin',
                  'AcademicConsultant',
                ].includes(user.role)
              "
              color="primary"
            >
              {{ $_t("attribute.fixReservations") }}
            </v-btn>
          </template>
          <template v-slot:baseType="{ item }">
            {{ $_t(`attribute.${item.baseType}`) }}
          </template>
          <template v-slot:level="{ item }">
            {{ $_t(`attribute.${item.classroomLevel}`) }}
          </template>
          <template v-slot:type="{ item }">
            {{ item.type }}
          </template>
          <template v-slot:times="{ item }">
            <CLassTimesModal
              @update="initClasses({})"
              :times="item.times"
              :single-class="item"
            >
              <template v-slot:activator>
                <v-badge
                  color="primary"
                  bordered
                  overlap
                  :content="item.times.length"
                >
                  <v-badge
                    color="red"
                    bordered
                    overlap
                    left
                    :content="
                      item.times.filter(
                        (t) => t.color === '#EF2929' || t.color === '#a45f4b'
                      ).length
                    "
                  >
                    <v-btn color="primary" outlined>
                      {{ $_t("attribute.time_details") }}
                    </v-btn>
                  </v-badge>
                </v-badge>
              </template>
            </CLassTimesModal>
          </template>
          <template v-slot:financialState="{ item }">
            {{ $_t(`attribute.${item.financialState}`) }}
          </template>
          <template v-slot:state="{ item }">
            {{ $_t(`attribute.${item.state}`) }}
          </template>
          <template v-slot:detail="{ item }">
            <v-btn @click="showDetail(item)" color="primary" outlined>{{
              $_t("attribute.details")
            }}</v-btn>
          </template>
          <template v-slot:edit="{ item }">
            <v-btn
              @click="editClass(item)"
              :disabled="item.state === 'Archived'"
              color="primary"
              outlined
              v-if="['Admin', 'SchedulingAdmin'].includes(role)"
              class="elevation-0"
            >
              <div class="txt-purple">
                {{ $_t("attribute.edit") }}
              </div>
            </v-btn>
          </template>
          <template v-slot:delete="{ item }">
            <div class="d-flex flex-fill">
              <ConfirmDelete @confirm="deleteItem(item)">
                <template #activator>
                  <v-btn
                    v-if="['Admin', 'SchedulingAdmin'].includes(role)"
                    text
                    :color="'red'"
                    :disabled="is_loading"
                    class="ml-3"
                  >
                    {{ $_t("attribute.delete") }}
                  </v-btn>
                </template>
              </ConfirmDelete>
              <v-btn
                v-if="['Admin'].includes(role)"
                text
                :color="item.state === 'Deleted' ? 'green' : 'red'"
                :disabled="is_loading"
                class="ml-3"
                @click="disableClass(item)"
              >
                {{
                  item.state === "Deleted"
                    ? $_t("attribute.active")
                    : $_t("attribute.disable")
                }}
              </v-btn>
              <v-btn
                v-if="
                  ['Admin', 'AcademicConsultant', 'SchedulingAdmin'].includes(
                    role
                  )
                "
                text
                :color="item.state === 'Archived' ? 'green' : 'red'"
                :disabled="is_loading"
                class="ml-3"
                @click="archiveClass(item)"
              >
                {{
                  item.state === "Archived"
                    ? $_t("attribute.restore")
                    : $_t("attribute.Archive")
                }}
              </v-btn>
            </div>
          </template>
          <template v-slot:pagination>
            <v-pagination
              v-model="pageInfo.page"
              :length="pageInfo.pageCount"
              :total-visible="5"
              @input="initClasses({})"
            ></v-pagination>
          </template>
        </MainTable>
        <EditClassModal @update="initClasses({})" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import MainTable from "@/packages/admin/components/MainTable";
import { mapGetters } from "vuex";
import { reactive, ref } from "vue";
import { lessons_filter } from "@/packages/admin/schema/calender/LESSONS_ARRAY";
import { CLASS_TABLE_SCHEMA } from "@/packages/admin/schema/CLASS_TABLE_HEADER";
import { getMainTime } from "@/tools/Utils";
import EditClassModal from "@/packages/admin/components/classRoom/details/modal/EditClassModal";
import CLassTimesModal from "@/packages/admin/components/classRoom/details/CLassTimesModal";
import ConfirmDelete from "@/components/app/ConfirmDelete";
import { useRoute } from "vue-router/composables";

export default {
  name: "ClassLists",
  components: {
    ConfirmDelete,
    CLassTimesModal,
    EditClassModal,
    MainTable,
    TopTitle,
  },
  computed: {
    ...mapGetters({
      classes: "class/get_classes",
      is_loading: "loading/is_loading",
      teachers: "admin/get_teachers",
      consultants: "admin/get_consultants",
      students: "admin/get_users",
      user: "authentication/user",
    }),
  },
  data() {
    return {
      search: "",
      getMainTime,
      classSettlements: [],
      singleClass: null,
      teacherPublicKey: [],
      consultantPublicKey: null,
      studentPublicKey: null,
    };
  },
  props: {
    role: {
      type: String,
    },
  },
  setup({ role }) {
    const lessonType = ref(null);
    const baseType = ref(null);
    const baseTypeFilter = lessons_filter;
    const route = useRoute();
    const headers =
      role === "Teacher"
        ? CLASS_TABLE_SCHEMA.teacher_headers
        : CLASS_TABLE_SCHEMA.headers;
    const filters = CLASS_TABLE_SCHEMA.filters;
    const pageInfo = reactive({
      page: Number(route.query?.page) || 1,
      pageCount: 1,
      itemsPerPage: 10,
    });
    return {
      lessonType,
      baseType,
      baseTypeFilter,
      headers,
      pageInfo,
      filters,
    };
  },
  methods: {
    async initClasses({ search = this.search }) {
      this.search = search;
      const { pageCount, editedTimeClasses } = await this.$actions.getClasses({
        page: this.pageInfo.page,
        limit: this.pageInfo.itemsPerPage,
        level: this.lessonType,
        teacherPublicKey:
          this.role === "Teacher"
            ? [this.user.publicKey]
            : this.teacherPublicKey,
        filterByStudent: this.role === "Teacher" ? null : this.studentPublicKey,
        search: this.search,
        baseType: this.baseType,
        filterByAcademicConsultant: this.consultantPublicKey,
        // dispatch: "class/setClasses",
        role: this.role,
      });
      await this.$store.dispatch("class/setClasses", editedTimeClasses);
      await this.$router
        .push({
          query: { page: this.pageInfo.page.toString() },
        })
        .catch(() => false);
      this.pageInfo.pageCount = pageCount;
    },
    async fetchTeachers(search) {
      await this.$actions.getUsers({
        all: true,
        role: "Teacher",
        search,
        dispatch: "admin/setTeachers",
        ignoreRoleFilter: ["FinancialAdmin", "AssistantTeacher"].includes(
          this.user.role
        ),
      });
    },
    async fetchConsultants(search) {
      await this.$actions.getUsers({
        all: true,
        role: "AcademicConsultant",
        search,
        dispatch: "admin/setAcademicConsultant",
        ignoreRoleFilter: [
          "AcademicConsultant",
          "FinancialAdmin",
          "TeamLeader",
          "SchedulingAdmin",
          "AssistantTeacher",
        ].includes(this.user.role),
      });
    },
    async fetchStudents(search) {
      await this.$actions.getUsers({
        all: true,
        role: "Student",
        dispatch: "admin/setUsers",
        search,
        ignoreRoleFilter: ["FinancialAdmin", "AssistantTeacher"].includes(
          this.user.role
        ),
      });
    },
    showDetail(item) {
      const routeData = this.$router.resolve({
        name: "admin.class_details",
        params: { id: item.classroomId },
      });
      window.open(routeData.href, "_blank");
    },
    async deleteItem(item) {
      await this.$actions.deleteClass({
        classroomId: item.classroomId,
      });
      await this.initClasses({});
    },
    async disableClass(item) {
      await this.$actions.disableClass({
        classroomId: item.classroomId,
        shouldDelete: item.state !== "Deleted",
      });
      await this.initClasses({});
    },
    async archiveClass(item) {
      await this.$actions.toggleArchiveClass({
        classroomId: item.classroomId,
        shouldArchive: item.state !== "Archived",
      });
      await this.initClasses({});
    },
    async editClass(item) {
      console.log(item, "class");
      this.$store.commit("class/updateClass", item);
      this.$store.commit("dialog/setDialog", true);
    },
    async fixReservations() {
      await this.$actions.fixConfirmationOfReservedSessionsOfClassrooms();
    },
  },
  async created() {
    await this.initClasses({});
    await this.fetchTeachers();
    await this.fetchConsultants();
    await this.fetchStudents();
  },
};
</script>

<style scoped></style>
