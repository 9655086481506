<template>
  <v-dialog v-model="timesModal" width="800">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-bind="attrs" v-on="on">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card v-if="times">
        <v-list-item
          two-line
          v-for="(time, index) in times"
          :key="index"
          dark
          :style="`background-color:${time.color};`"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{
                `${$_t("attribute.classroomNumber")} : ${time.classroomNumber}`
              }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                `${$_t("attribute.start_date")} : ${$_date(
                  time.start * 1000
                )} => ${getMainTime(
                  time.start * 1000,
                  "h:mm a"
                )} : ${getMainTime(time.end * 1000, "h:mm a")} `
              }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="time.message">
              {{ `${$_t("attribute.description")}: ${time.message}` }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon
            v-if="['Admin', 'TeamLeader', 'SchedulingAdmin'].includes(role)"
          >
            <v-btn
              color="primary"
              :disabled="isTimeConfirmedOrCaneled(time)"
              @click="openConfirmModal(time)"
              >{{ $_t("attribute.cancelTime") }}</v-btn
            >
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-actions>
          <ClassStateColor />
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="timesModal = false">
            {{ $_t("attribute.close") }}
          </v-btn>
          <ConfirmDelete @confirm="removeTimes">
            <template #activator>
              <v-btn
                color="red"
                v-if="['Admin', 'SchedulingAdmin'].includes(role)"
                text
              >
                {{ $_t("attribute.removeTimes") }}
              </v-btn>
            </template>
          </ConfirmDelete>
        </v-card-actions>
      </v-card>
      <ConfirmModal
        showMessage
        @confirm="cancelTime"
        ref="confirmModalRef"
      ></ConfirmModal>
    </template>
  </v-dialog>
</template>

<script>
import ClassStateColor from "@/packages/admin/components/classRoom/ClassStateColor";
import { getMainTime } from "@/tools/Utils";
import { mapGetters } from "vuex";
import ConfirmDelete from "@/components/app/ConfirmDelete";
import ConfirmModal from "@/components/app/ConfirmModal";

export default {
  name: "CLassTimesModal",
  components: { ConfirmModal, ConfirmDelete, ClassStateColor },
  data() {
    return {
      timesModal: false,
      getMainTime,
      time: {},
    };
  },
  props: {
    times: {
      type: Array,
      required: true,
    },
    singleClass: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      role: "authentication/role",
    }),
  },
  methods: {
    async removeTimes() {
      const res = await this.$actions.removeTimesOfClassroom({
        classroomId: this.singleClass.classroomId,
      });
      if (res) this.$emit("update");

      this.timesModal = false;
    },
    openConfirmModal(time) {
      this.time = time;
      this.$refs.confirmModalRef.open();
    },
    async cancelTime({ message }) {
      const res = await this.$actions.cancelTimeInClass({
        classroomId: this.singleClass.classroomId,
        availableTimeId: this.time.availableTimeId,
        start: this.time.start,
        end: this.time.end,
        description: message,
      });
      if (res) this.$emit("update");
      this.timesModal = false;
    },
    isTimeConfirmedOrCaneled(item) {
      if (["Cancel", "Passed"].includes(item.state)) return true;
      return false;
    },
  },
};
</script>

<style scoped></style>
